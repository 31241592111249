import React, { useContext } from 'react';
import { Query } from 'react-apollo';
import styled from 'react-emotion';

import ChannelContext from '@jetshop/core/components/ChannelContext';
import t from '@jetshop/intl';
import theme from '@jetshop/ui/utils/theme';
import CategoryLink from '@jetshop/ui/CategoryLink';

import MaxWidth from '../MaxWidth';
import NewsletterField from '../../Cart/Newsletter/NewsletterField';
import ContactInfo from './ContactInfo';
import SocialLinks from './SocialLinks';
import FooterQuery from './FooterQuery.gql';
import { ReactComponent as AvardaNew2Svg } from '../../../svg/avarda_new2.svg';

const Wrapper = styled('section')`
  text-align: left;
  font-size: 0.875rem;

  h2 {
    margin-bottom: 0.5rem;
    font-family: ${({ theme }) => theme.fonts.body};
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  a,
  p {
    letter-spacing: 1px;
  }
  a {
    padding: 0.5rem 0;
    display: block;
    color: ${({ theme }) => theme.colors.black};
    text-decoration: none;
  }
`;

const WrapFooterNav = styled(MaxWidth)`
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  margin-bottom: 2rem;
  > section {
    margin: 0 2rem 0 0;
    flex: 0 1 25%;
    &:last-of-type {
      margin: 0;
    }
  }
  ${theme('below.lg')} {
    flex-wrap: wrap;
    padding: 0 20px;
  }
`;

const NewsletterWrapper = styled('div')`
  background: ${theme('colors.background')};
  text-align: center;
  margin-bottom: 30px;
  padding: 3rem 0 2rem 0;
  ${theme('below.lg')} {
    padding: 3rem 20px 2rem 20px;
  }
`;

const ExtraLinks = styled('section')`
  a {
    line-height: 1.5;
    padding: 0;
    transition: all ease 0.2s;
    &:hover {
      color: ${theme('colors.grey')};
    }
  }
`;

const PoweredByWrapper = styled('div')`
  color: #7d7d7d;
  margin: auto;
  padding: 1rem;
  text-align: center;
  width: 100%;
  letter-spacing: 0.5px;
  + div {
    z-index: 1;
  }
`;

const Left = styled('div')`
  display: flex;
  align-items: flex-start;
  > section {
    margin-right: 50px;
  }
  ${theme('below.lg')} {
    flex-wrap: wrap;
  }
`;
const Right = styled('div')`
  img {
    width: 400px;
    max-width: 100%;
  }
  ${theme('below.lg')} {
    flex-wrap: wrap;
    margin-top: 2rem;
  }

  svg {
    width: 400px;
    max-width: 100%;
  }
`;

const CategoryColumn = ({ id }) => {
  const { selectedChannel } = useContext(ChannelContext);
  return (
    <Query query={FooterQuery} variables={{ id }}>
      {({ loading, error, data }) => {
        if (loading || error) return null;
        return (
          <>
            {data.category && (
              <React.Fragment>
                <h2>{data.category.name}</h2>
                <ul>
                  {data.category.subcategories.map((subcat, i) => (
                    <li key={i}>
                      <CategoryLink category={subcat}>
                        {subcat.name}
                      </CategoryLink>
                    </li>
                  ))}
                  {selectedChannel.name.indexOf('B2B') > -1 ? null : (
                    <li>
                      <a href="https://cln.gung.io/login" target="_blank">
                        {t('Business login')}
                      </a>
                    </li>
                  )}
                </ul>
              </React.Fragment>
            )}
          </>
        );
      }}
    </Query>
  );
};

const Footer = () => {
  const { selectedChannel } = useContext(ChannelContext);
  return (
    <>
      <NewsletterWrapper>
        <NewsletterField />
      </NewsletterWrapper>

      <Wrapper>
        <WrapFooterNav>
          <Left>
            <ContactInfo />

            <SocialLinks />

            <ExtraLinks>
              {selectedChannel.id !== 6 ? (
                <>
                  <CategoryColumn id={222} />
                  {selectedChannel.name.indexOf('B2B') > -1 ? (
                    <ul>
                      <li>
                        <a href="https://clnathletics.com/?culture=sv-SE&currency=SEK&channelCountry=SE&channel=M1%20-%20SE">
                          {t('Back to Shop')}
                        </a>
                      </li>
                    </ul>
                  ) : null}
                </>
              ) : null}
            </ExtraLinks>
          </Left>

          <Right>
            <AvardaNew2Svg />
          </Right>
        </WrapFooterNav>
      </Wrapper>

      <PoweredByWrapper></PoweredByWrapper>
    </>
  );
};

export default Footer;
